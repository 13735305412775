$(document).ready(function(){

	//nojs
	$('body').removeClass('no-js');

	//------------------------------------------------------------------------//

	//fakelink
	$('a[href="#"]').on('click', function(event) {
		event.preventDefault();
	});

	//------------------------------------------------------------------------//

	//drop down menu
	//$('.menu li').has('ul').addClass('dropdown');
	//$('.menu li').hover(function(){$('ul',this).slideDown(0);},function(){$('ul',this).slideUp(0);});
	//doubleTapToGo
	//$('.menu > li:has(ul)').doubleTapToGo();

	//------------------------------------------------------------------------//

	//menu
	$('.menu-toggle').on('click', function(event) {
		event.preventDefault();
		$('.navigation').toggleClass('open');
	});

	//------------------------------------------------------------------------//

	//placeholder
	$('input[placeholder], textarea[placeholder]').placeholder();

	//------------------------------------------------------------------------//

	//author popup
	$('.author-popup-link').on('click', function() {
		$(this).parent().next().addClass('active').next().addClass('active');
	});

	$('.author-popup-close, .overlay').on('click', function() {
		$('.author-popup, .overlay').removeClass('active');
	});

	//------------------------------------------------------------------------//

	//counter buttons
	var counterInput = $('.counter-input input');

	$('.counter-btn').on('click', function(event) {
		event.preventDefault();
	});
	$('.minus-btn').on('click', function() {
		var counterValue = parseInt(counterInput.val());
		if (counterValue > 1) {
			counterValue--;
		}
		counterInput.val(counterValue);
	});
	$('.plus-btn').on('click', function() {
		var counterValue = parseInt(counterInput.val());
		if (counterValue < 1000) {
			counterValue++;
		}
		counterInput.val(counterValue);
	});

	//------------------------------------------------------------------------//

});//document ready

$(window).load(function() {

	//load

});//window load